import React from "react";

export default function Contact() {
  return (
    <>
      <h2>Contact</h2>
      <ul>
        <li>
          <a href='mailto:matrixwork@att.net'>
            matrixwork
            <span>@</span>
            att.net
          </a>
        </li>
        <li>
          <a href='tel:1-847-207-8002'>847.207.8002</a>
        </li>
      </ul>
    </>
  );
}
