import React, { useState } from "react";
import { NavLink, BrowserRouter as Router, Route } from "react-router-dom";
import imageManifest from "./image-manifest.json";
import Cards from "./Cards";
import About from "./About";
import Contact from "./Contact";
import "./App.css";
import Modal from "./Modal.js";

const getUserFaves = () => {
  let userFaves = [];
  try {
    const faves = window.localStorage.getItem("matrixFaves") || "[]";
    let saved = JSON.parse(faves);
    if (!Array.isArray(saved)) {
      saved = Object.keys(saved);
    }
    userFaves = saved;
  } catch (error) {
    console.error("error with your favorites", error);
  }

  return userFaves;
};

function App(props) {
  console.log("%cnew %o", "color:magenta", "");
  const [imageIdx, setImageIdx] = useState(null);
  const [images, setImages] = useState(() => {
    const faves = getUserFaves();
    return imageManifest.map(img => ({
      imgSrc: img,
      isFave: faves.includes(img)
    }));
  });

  const handleCardClick = imgIdx => {
    setImageIdx(imgIdx);
  };

  const handleModalNext = () => {
    let nextImageIdx = imageIdx + 1;
    if (nextImageIdx === imageManifest.length) {
      nextImageIdx = 0;
    }
    setImageIdx(nextImageIdx);
  };

  const handleModalPrev = () => {
    let nextImageIdx = imageIdx - 1;
    if (nextImageIdx < 0) {
      nextImageIdx = imageManifest.length - 1;
    }
    setImageIdx(nextImageIdx);
  };

  const handleModalClose = () => {
    setImageIdx(null);
  };

  const toggleFavorite = (imgSrc, idx) => {
    const userFaves = getUserFaves();
    if (userFaves.includes(imgSrc)) {
      userFaves.splice(userFaves.indexOf(imgSrc), 1);
    } else {
      userFaves.push(imgSrc);
    }
    window.localStorage.setItem("matrixFaves", JSON.stringify(userFaves));
    const updatedImages = [...images];
    const updatedImage = { ...updatedImages[idx] };
    updatedImage.isFave = !updatedImage.isFave;
    updatedImages[idx] = updatedImage;
    setImages(updatedImages);
  };

  return (
    <Router>
      {imageIdx !== null && (
        <Modal
          onClose={handleModalClose}
          onNext={handleModalNext}
          onPrev={handleModalPrev}
        >
          <img
            alt={`${imageManifest[imageIdx]}`}
            src={`images/full/${imageManifest[imageIdx]}`}
          />
        </Modal>
      )}
      <header className='nav-wrapper'>
        <h1 className='accent'>Matrix</h1>
        <nav>
          <NavLink
            name='all images'
            tabIndex='0'
            to='/'
            className='nav-item'
            activeClassName='selected'
          >
            <i className='icon icon-grid' />
            <span className='nav-text'>All</span>
          </NavLink>
          <NavLink
            name='favorites'
            tabIndex='1'
            to='/faves'
            className='nav-item'
            activeClassName='selected'
          >
            <i className='icon icon-heart' />
            <span className='nav-text'>Favorites</span>
          </NavLink>
          <NavLink
            name='contact'
            tabIndex='2'
            to='/contact'
            className='nav-item'
            activeClassName='selected'
          >
            <i className='icon icon-phone2' />
            <span className='nav-text'>Contact</span>
          </NavLink>
          <NavLink
            name='about'
            tabIndex='3'
            to='/about'
            className='nav-item'
            activeClassName='selected'
          >
            <i className='icon icon-info' />
            <span className='nav-text'>About</span>
          </NavLink>
        </nav>
      </header>
      <main className='wrapper'>
        <Route
          exact
          path='/'
          render={props => (
            <Cards
              {...props}
              onClick={handleCardClick}
              toggleFavorite={toggleFavorite}
              images={images}
            />
          )}
        />
        <Route
          exact
          path='/faves'
          render={props => (
            <Cards
              {...props}
              onClick={handleCardClick}
              images={images.filter(i => i.isFave)}
              toggleFavorite={toggleFavorite}
            />
          )}
        />
        <Route path='/about' component={About} />
        <Route path='/contact' component={Contact} />
      </main>
    </Router>
  );
}

export default App;
