import React from "react";

export default function Modal({ children, onNext, onPrev, onClose }) {
  return (
    <div>
      <div className='modal-background' />
      <div className='modal'>
        <div className='link modal-close' onClick={onClose}>
          <svg width='32' height='32' viewBox='0 0 32 32'>
            <line
              x1='0'
              x2='50%'
              y1='0'
              y2='50%'
              stroke='#333'
              strokeWidth='2'
            />
            <line
              x1='50%'
              x2='0'
              y1='0'
              y2='50%'
              stroke='#333'
              strokeWidth='2'
            />
          </svg>
        </div>
        {children}
        <div onClick={onNext} className='modal-nav next'>
          <i className='icon icon-chevron-thin-right' />
        </div>
        <div onClick={onPrev} className='modal-nav prev'>
          <i className='icon icon-chevron-thin-left' />
        </div>
      </div>
    </div>
  );
}
