import React from "react";

function Card({
  width = 200,
  height = 200,
  imgSrc,
  idx,
  loaded = true,
  onClick,
  isFave,
  toggleFavorite
}) {
  const handleLoad = () => {};

  return (
    <div className='card'>
      <div onClick={onClick} className='inner-card'>
        <img
          onLoad={handleLoad}
          src={`images/thumb/${imgSrc}`}
          title={imgSrc}
          alt={imgSrc}
        />
        <div className='card-border' />
        {!loaded && (
          <span className='card-placeholder'>
            <svg className='circular' viewBox='25 25 50 50'>
              <circle
                className='path'
                cx='50'
                cy='50'
                fill='none'
                r='20'
                strokeMiterlimit='10'
                strokeWidth='2'
              />
            </svg>
          </span>
        )}
      </div>
      <button
        name={isFave ? "unfavorite" : "favorite"}
        className='icon-btn'
        onClick={() => {
          toggleFavorite(imgSrc, idx);
        }}
      >
        <i
          className={`fave icon ${
            isFave ? "icon-heart" : "icon-heart-outline"
          }`}
        />
      </button>
    </div>
  );
}

export default function Cards({ images, onClick, toggleFavorite }) {
  if (!images.length) {
    return <p className='about-text'>No favorites</p>;
  }

  return (
    <div className='card-wrapper'>
      {images.map(({ imgSrc, isFave }, idx) => (
        <Card
          isFave={isFave}
          toggleFavorite={toggleFavorite}
          key={imgSrc}
          idx={idx}
          onClick={() => {
            onClick(idx);
          }}
          imgSrc={imgSrc}
        />
      ))}
    </div>
  );
}
