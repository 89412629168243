import React from "react";

export default function About() {
  return (
    <>
      <h2>About</h2>
      <p className='about-text'>
        Created in 1935 by George Mohrlein Sr., Matrix was originally a machine
        shop. Today the shop is owned and run by his three sons, George Jr.,
        Bernie and John. Since 1935 Matrix has evolved into a atelier of sorts.
        Never forgetting our roots, many of these creations are powered,
        designed and constructed with Matrix-created stuff. It’s all very high
        art and cool but never snobby. We pride ourselves on continuing a local
        family tradition and making Chicago prettier and more efficient one
        copper dome at a time. We’re not your father’s welder...
      </p>
    </>
  );
}
